/* @import url("https://fonts.googleapis.com/css2?family=DotGothic16&display=swap"); */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* リセットCSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  color: inherit;
}

/* 全体のスタイル */
body {
  font-family: "DotGothic16", sans-serif;
  font-size: 16px;
  color: #000000;
  background-color: #cccccc;
  position: relative;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 120px;
}

h2 {
  font-size : 25px;
  font-weight: bold;
  border: #000000;
  border-style:dotted;
  border-width: 0 0 2px 0;
  padding-bottom: 10px;
}

h3{
  font-size : 20px;
  font-weight: bold;
  margin: 15px;
}

img {
  margin: auto;
  display: block;
}

/* home */
.home{
  flex-basis: 80%;
}

/* headerのスタイル */
.kurosuke-img{
  position: absolute;
  display: block;
  left: 30px;
}

.header-title {
  font-size: 24px;
  font-weight: bold;
}

.gnavi__pc-style nav {
  padding: 30px;
}

.gnavi__pc-style ul {
  display: flex;
  list-style: none;
  padding-right: 30px;
}

.gnavi__pc-style li {
  margin-left: 100px;
}

.gnavi__pc-style a {
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  transition: 0.3s ease;
}

.gnavi__pc-style a:hover {
  color: #007bff;
}

/* mainのスタイル */
main {
  margin: 80px auto 0 auto;
  max-width: 900px;
}

section {
  padding: 40px 20px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

/* contentsのスタイル */
.contents {
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;
}

/* sideのスタイル */
.side {
  flex-basis: 20%;
  border-left: groove;
}
.profile_image {
  display: flex;
  justify-content: center;
}
/* cardのスタイル */

.title {
  font-size: 30px;
  font-weight: bold;
  padding-left: 30px;
}

.date {
  padding-top: 30px;
}

.image {
  padding: 30px 30px 0px 30px;
  text-align: center;
}

.blog_img {
  width: 60%;
  margin: auto;
  display: block;
}

.text-body {
  padding: 30px;
  font-size: 20px;
}

.text-body p{
  margin: 15px;
}

.items {
  padding: 0 100px;
  grid-template-columns: repeat(auto-fit, minmax(min(240px, 100%), 1fr));
  gap: 2rem;
  display: flex;
  flex-wrap: wrap;
  flex-basis: 80%;
}

.blog_items {
  padding: 60px;
  width: 1000px;
  grid-template-columns: repeat(auto-fit, minmax(min(240px, 100%), 1fr));
  gap: 3rem;
  display: block;
  margin: auto;
  background: #ffffff;
  box-sizing: border-box;
  border: solid 3px #000000;
  border-radius: 20px;
}
.card {
  width: 288px;
  height: auto;
}

.card__textbox {
  width: 800px;
  height: auto;
  padding: 20px 18px;
  background: #ffffff;
  box-sizing: border-box;
  font-weight: bold;
  border: solid 3px #000000;
  border-radius: 20px;
}

.card__textbox > * + * {
  margin-top: 10px;
}

.card__titletext {
  font-size: 25px;
  font-weight: bold;
  line-height: 125%;
}

.card__titletext_createdAt{
  border-bottom: 2px;
  border-style: solid;
  width: 20%;
}

.card__overviewtext {
  font-size: 12px;
  line-height: 150%;
}

.card-skin {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* footerのスタイル */
footer {
  text-align: center;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  color: #ffffff;
  background-color: #000000;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  position: absolute;
  height: 80px;
  bottom: 0;
  left: 0;
  right: 0;
}

/* スマホサイズのレスポンシブ対応 */
@media (max-width: 600px) {

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 15px;
  }

  body {
    padding-top: 50px;
    padding-bottom: 0px;
  }

  .header-title {
    font-size: 20px;
  }

  .gnavi__pc-style ul {
    flex-direction: column;
    padding: 0;
  }

  .gnavi__pc-style li {
    margin-left: 0;
    margin-bottom: 10px;
  }

  main {
    margin: 40px 20px;
    max-width: 100%;
  }

  .title {
    font-size: 25px;
    font-weight: bold;
    padding-left: 0px;
    text-align: center;
  }

  .side {
    flex-basis: 100%;
    border-left: none;
    border-top: groove;
  }

  .home{
    flex-basis: 100%;
  }

  .text-body p{
    margin: 15px;
    font-size: medium;
  }

  .items, .blog_items {
    padding: 0px;
    width: auto;
    margin: 20px;
  }

  .card__textbox {
    width: auto;
  }

  .card__titletext_createdAt{
    border-bottom: 2px;
    border-style: solid;
    width: unset;
  }

  .contents{
    justify-content: space-around;
    padding-top: 25px;
  }

  footer {
    position: relative;
  }
}
